/* 为所有的图片添加一个背景色，置于上层 */
.main::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, #070908, transparent);
  z-index: 10;
}

.genBgBottom::before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #070908, transparent);
  z-index: 22;
}

.genBgBottom {
  width: 100%;
  bottom: 0;
  height: 100px;
}

/* 利用颜色混合模式修改图片的色调，关键代码最后一行 */
.main::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.main {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.genBg {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  pointer-events: none;
}

.index-bg {
}

/* 为所有的图片添加一个背景色，置于上层 */
.index-bg::before {
  content: '';
  position: absolute;
  bottom: 10%;
  width: 100%;
  height: 10%;
  z-index: 0;
  background: linear-gradient(to bottom, #08060b, transparent);
}

.zIndex-1 {
  z-index: -1;
}

.zIndex-2 {
  z-index: 1;
}

.image-drag-none {
  -webkit-user-drag: none;
  z-index: -1;
}

.image-drag {
  -webkit-user-drag: none;
  z-index: 10;
}

.a-underline {
  padding-bottom: 10px;
  border-bottom: 100px;
}

.image-drag-none-1 {
  -webkit-user-drag: none;
}

.bottom::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 70px;
  background: linear-gradient(to bottom, #070908, transparent);
  z-index: 10;
}

.image-bg-opacity {
  opacity: 0.4;
}

.bg-wrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
}

.clickable {
  cursor: pointer;
}

.clickable:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
  opacity: 0.65;
}

.clickable:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
  opacity: 0.85;
  transform: translateY(1px);
  box-shadow: none;
}
